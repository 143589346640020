<template>
  <div v-if="formAction" style="padding: 20px">
    <div class="titles">{{ __("Account") }}</div>
    <div class="details">{{ contentFormInfo.ac_name }}</div>
    <div class="titles">{{ __("Business Plan") }}</div>
    <div class="details">{{ contentFormInfo.bp_name }}</div>
    <div class="titles">
      {{ __("Units Allocated") }}
    </div>
    <div
      v-if="
        contentFormInfo.plan_type === 'QforMe Standard' ||
          contentFormInfo.plan_type === 'QforMe Premium'
      "
    >
      <div class="titles">{{ __("Pooling Method") }}</div>
      <div class="details">{{ contentFormInfo.qfm_pooling_method }}</div>
    </div>
    <div v-else class="'details">
      {{ contentForm.allocated_unit }}
    </div>
    <el-button @click="handleEdit" class="editBtn" :disabled="!canWrite()">{{
      __("Edit")
    }}</el-button>
    <el-button
      @click="handleDelete(contentFormInfo)"
      class="deleteBtn"
      :disabled="!canWrite()"
      >{{ __("Delete") }}
    </el-button>
    <div
      class="details"
      style="padding-top: 20px"
      v-if="
        formAction === 'select' &&
          contentFormInfo.series_data &&
          contentFormInfo.series_data.length
      "
    >
      <plan-hierarchy :series-data="contentFormInfo.series_data">
      </plan-hierarchy>
    </div>

    <!-- AC PLAN FORM -->
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="plan-form-dialog" v-loading="isLoading || isReceiving">
        <el-scrollbar :native="false">
          <div>
            <el-form
              ref="contentForm"
              :rules="rules"
              :model="contentForm"
              label-position="top"
              size="large"
            >
              <el-row type="flex" style="padding-top: 34px">
                <el-col :span="18" :offset="6">
                  <!-- PAGE HEADER -->
                  <el-row type="flex">
                    <el-col :span="22">
                      <page-header
                        style="padding-left: 0"
                        :title="setPageHeader"
                      />
                    </el-col>
                  </el-row>
                  <!-- ACCOUNT SELECTION -->
                  <el-row type="flex">
                    <el-col :span="22">
                      <el-form-item :label="__('Account')" prop="ac_id">
                        <el-select
                          filterable
                          v-model="contentForm.ac_id"
                          default-first-option
                          style="width: 100%"
                          @change="showPlans"
                        >
                          <el-option
                            v-for="ac in this.accounts"
                            :key="ac.ac_id"
                            :label="ac.ac_name"
                            :value="ac.ac_id"
                          >
                          </el-option>
                        </el-select>
                        <div class="info" v-show="accounts.length === 0">
                          <div class="warning-exclamation" />
                          <div style="margin-left: 5px; display: flex">
                            <div>
                              <!-- eslint-disable-next-line -->
                              {{ __("There are no accounts left to be assigned the :plan_type plan", {
                                    plan_type: contentForm.plan_type
                                  }
                                )
                              }}
                            </div>
                          </div>
                        </div>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- BP SELECTION -->
                  <el-row type="flex">
                    <el-col :span="22">
                      <el-form-item
                        :label="__('Business Plan')"
                        prop="sp_plan_id"
                      >
                        <el-select
                          v-model="contentForm.sp_plan_id"
                          filterable
                          style="width: 100%"
                          :no-data-text="__('Please select an account')"
                          v-loading="this.isReceiving"
                          @change="changeUnitAndPrice"
                          default-first-option
                          :disabled="contentForm.ac_id === ''"
                        >
                          <el-option
                            v-for="sp_plan in this.availablePlans"
                            :key="sp_plan.sp_plan_id"
                            :label="sp_plan.sp_plan_name"
                            :value="sp_plan.sp_plan_id"
                          >
                            <span style="float: left">{{
                              sp_plan.sp_plan_name
                            }}</span>
                            <span
                              style="float: right; color: #8492a6; font-size: 13px"
                              >{{ sp_plan.bp_name }}</span
                            >
                          </el-option>
                          <template
                            v-slot:empty
                            v-if="
                              this.availablePlans &&
                                this.availablePlans.length === 0
                            "
                          >
                            <p class="el-select-dropdown__empty">
                              <!-- eslint-disable-next-line -->
                              {{ __("Currently, there is no :plan_type business plan available which can be assigned to this account.", {
                                    plan_type: contentForm.plan_type
                                  }
                                )
                              }}
                              <br />
                              <!-- eslint-disable-next-line -->
                              {{ __("Either a business plan has been already assigned or a plan is not assigned to the service provider.") }}
                            </p>
                          </template>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <!-- ALLOCATED UNIT SLIDER -->
                  <div v-if="contentForm.pooling_method === 'Flexible'">
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          :label="__('Allocated Units')"
                          prop="allocated_unit"
                          :required="contentForm.pooling_method === 'Flexible'"
                        >
                          <el-slider
                            style="margin-left: 5px"
                            v-model="contentForm.allocated_unit"
                            show-input
                            :min="1"
                            :max="sp_allocated_unit"
                            :show-tooltip="false"
                          ></el-slider>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- ALLOCATED UNIT -->
                  <div
                    v-if="
                      contentForm.pooling_method === 'Strict' &&
                        (contentForm.plan_type !== 'DVA' ||
                          (contentForm.plan_type === 'DVA' &&
                            !this.effective_date_type))
                    "
                  >
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          :label="__('Allocated Units')"
                          prop="allocated_unit"
                          :required="contentForm.pooling_method === 'Strict'"
                        >
                          <el-input-number
                            size="small"
                            v-model="contentForm.allocated_unit"
                            :disabled="contentForm.ac_id === ''"
                            :min="0"
                            @change="handleUnitsChange(false)"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- DVA NEXT ALLOCATED UNIT -->
                  <div
                    v-else-if="
                      contentForm.plan_type === 'DVA' &&
                        this.effective_date_type
                    "
                  >
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          :label="__('Allocated Units')"
                          prop="next_allocated_unit"
                          :required="
                            contentForm.pooling_method === 'Strict' &&
                              this.effective_date_type
                          "
                        >
                          <el-input-number
                            size="small"
                            v-model="contentForm.next_allocated_unit"
                            :disabled="contentForm.ac_id === ''"
                            :min="0"
                            @change="handleUnitsChange(true)"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- QFORME RADIO BUTTON FOR STRICT AND FLEXIBLE -->
                  <div
                    v-if="
                      contentForm.plan_type === 'QforMe Standard' ||
                        contentForm.plan_type === 'QforMe Premium'
                    "
                  >
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-row style="height: 30px;">
                          <el-form-item
                            :label="__('Queue For Me Pooling Method')"
                          ></el-form-item>
                        </el-row>
                        <el-row>
                          <el-form-item>
                            <el-radio-group
                              v-model="contentForm.qfm_pooling_method"
                              :disabled="contentForm.ac_id === ''"
                            >
                              <el-radio label="Strict">{{
                                __("Strict")
                              }}</el-radio>
                              <el-radio label="Flexible"
                                >{{ __("Flexible") }}
                              </el-radio>
                            </el-radio-group>
                          </el-form-item>
                        </el-row>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- IVA BURST UNIT SLIDER -->
                  <div
                    v-if="
                      contentForm.pooling_method === 'Flexible' &&
                        contentForm.allow_bursting &&
                        contentForm.plan_type === 'IVA'
                    "
                  >
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          :label="__('Burst Units')"
                          prop="burst_unit"
                        >
                          <el-slider
                            style="margin-left: 5px"
                            v-model="contentForm.burst_unit"
                            show-input
                            :min="0"
                            :max="sp_burst_unit"
                            :show-tooltip="false"
                          ></el-slider>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- IVA BURST UNIT -->
                  <div
                    v-if="
                      contentForm.pooling_method === 'Strict' &&
                        contentForm.allow_bursting &&
                        contentForm.plan_type === 'IVA'
                    "
                  >
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          :label="__('Burst Units')"
                          prop="burst_unit"
                        >
                          <el-input-number
                            size="small"
                            v-model="contentForm.burst_unit"
                            :disabled="contentForm.ac_id === ''"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- DVA BURST PERCENTAGE -->
                  <div
                    v-if="
                      contentForm.allow_bursting &&
                        contentForm.plan_type === 'DVA' &&
                        !this.effective_date_type
                    "
                  >
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          ref="dvaBurstPercentageFormItem"
                          :label="__('Burst Percentage')"
                          prop="burst_unit"
                        >
                          <el-input-number
                            size="small"
                            :min="0"
                            :max="sp_burst_unit"
                            v-model="contentForm.burst_unit"
                            :disabled="contentForm.ac_id === ''"
                            @change="handleUnitsChange(false)"
                          ></el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- LIST PRICE -->
                  <div v-if="this.contentForm.sp_plan_id">
                    <el-row type="flex">
                      <el-col :span="4">
                        <el-form-item
                          :label="__('List Price')"
                          prop="list_price"
                        >
                          <el-input-number
                            size="small"
                            :min="0"
                            v-model="contentForm.sp_plan_list_price"
                            :disabled="true"
                          >
                          </el-input-number>
                        </el-form-item>
                      </el-col>
                      <el-col>
                        <el-form-item
                          style="margin-left:50px; margin-top:27px;"
                          prop="override_price"
                        >
                          <el-checkbox
                            v-model="contentForm.override_price"
                            :disabled="contentForm.ac_id === ''"
                            @change="updateUnitPrice"
                            >{{ __("Override Price") }}
                          </el-checkbox>
                          <span
                            v-if="contentForm.override_price"
                            style="font-size: small; margin-left:50px; margin-top:27px;"
                          >
                            {{
                              __(
                                "Please ensure you have written authority to override list pricing before continuing."
                              )
                            }}
                          </span>
                        </el-form-item>
                      </el-col>
                    </el-row>
                    <el-row type="flex">
                      <el-col :span="22">
                        <el-form-item
                          :label="__('Unit Price')"
                          prop="unit_price"
                          v-if="contentForm.override_price"
                        >
                          <el-input-number
                            size="small"
                            :min="0"
                            v-model="contentForm.unit_price"
                          >
                          </el-input-number>
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- DVA CURRENT/UPCOMING SWITCH -->
                  <div v-if="id !== -1 && contentForm.plan_type === 'DVA'">
                    <el-row type="flex"
                      ><el-col :span="22">
                        <el-switch
                          v-model="effective_date_type"
                          :active-text="__('Upcoming')"
                          :inactive-text="__('Current')"
                          @change="recalculateUsage"
                        >
                        </el-switch>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- DVA DATE PICKER -->
                  <div
                    v-if="
                      contentForm.plan_type === 'DVA' &&
                        this.contentForm.sp_plan_id &&
                        !this.effective_date_type
                    "
                    style="margin-top:27px;"
                  >
                    <el-row type="flex">
                      <el-col>
                        <el-form-item
                          :label="__('Effective From')"
                          prop="current_effective_from"
                        >
                          <el-date-picker
                            v-model="contentForm.current_effective_from"
                            type="month"
                            placeholder="Pick a month"
                            :disabled="id !== -1"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col>
                        <el-form-item
                          :label="__('Effective To')"
                          prop="current_effective_to"
                        >
                          <el-date-picker
                            v-model="contentForm.current_effective_to"
                            type="month"
                            placeholder="Pick a month"
                            :disabled="
                              contentForm.current_is_perpetual || id !== -1
                            "
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col>
                        <el-form-item
                          prop="current_is_perpetual"
                          style="margin-left:50px; margin-top:27px;"
                        >
                          <el-checkbox
                            v-model="contentForm.current_is_perpetual"
                            @change="updateEffectiveTo"
                            :disabled="id !== -1"
                            >{{ __("Perpetual") }}</el-checkbox
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- DVA NEXT DATE PICKER -->
                  <div
                    v-if="
                      id !== -1 &&
                        contentForm.plan_type === 'DVA' &&
                        this.contentForm.sp_plan_id &&
                        this.effective_date_type
                    "
                    style="margin-top:27px;"
                  >
                    <el-row type="flex">
                      <el-col>
                        <el-form-item
                          :label="__('Effective From')"
                          prop="next_effective_from"
                        >
                          <el-date-picker
                            v-model="contentForm.next_effective_from"
                            type="month"
                            placeholder="Pick a month"
                            @change="handleDateChange"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col>
                        <el-form-item
                          :label="__('Effective To')"
                          prop="next_effective_to"
                        >
                          <el-date-picker
                            v-model="contentForm.next_effective_to"
                            type="month"
                            placeholder="Pick a month"
                            :disabled="contentForm.next_is_perpetual"
                          >
                          </el-date-picker>
                        </el-form-item>
                      </el-col>
                      <el-col>
                        <el-form-item
                          prop="next_is_perpetual"
                          style="margin-left:50px; margin-top:27px;"
                        >
                          <el-checkbox
                            v-model="contentForm.next_is_perpetual"
                            @change="updateNextEffectiveTo"
                            >{{ __("Perpetual") }}</el-checkbox
                          >
                        </el-form-item>
                      </el-col>
                    </el-row>
                  </div>
                  <!-- NEXT MONTH TOGGLE -->
                  <div
                    v-if="
                      id !== -1 &&
                        (contentForm.plan_type === 'IVA' ||
                          (contentForm.plan_type === 'DVA' &&
                            !this.effective_date_type))
                    "
                  >
                    <el-row type="flex"
                      ><el-col :span="22">
                        <el-switch
                          v-model="next_month_usage"
                          :active-text="__('Next Month')"
                          :inactive-text="__('Current Month')"
                          :disabled="disableNextMonthUsage"
                        >
                        </el-switch></el-col
                    ></el-row>
                  </div>

                  <!-- TABLE -->
                  <div style="padding-bottom: 10px">
                    <!-- //CREATE NEW AC PLAN OR CHECK NEXT MONTH TABLE -->
                    <div
                      v-if="
                        this.effective_date_type ||
                          ((id === -1 || this.next_month_usage) &&
                            this.contentForm.sp_plan_id)
                      "
                    >
                      <el-row
                        type="flex"
                        v-if="
                          contentForm.plan_type === 'IVA' ||
                            contentForm.plan_type === 'DVA' ||
                            contentForm.plan_type === 'Biometrics'
                        "
                        v-loading="isLoading || isReceiving"
                      >
                        <el-col :span="22">
                          <el-scrollbar :native="false">
                            <div class="contentListContainer">
                              <el-table
                                :data="this.featureLimits"
                                style="width: 100%;"
                                :cell-style="{
                                  padding: '3px',
                                  height: '18px !important'
                                }"
                              >
                                <el-table-column
                                  prop="featureName"
                                  :label="__('Feature')"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="upper_limit"
                                  :label="__('Usages/Unit')"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="currentPlanFeatureLimit"
                                  :label="__('Plan Limit')"
                                  v-if="contentForm.plan_type === 'DVA'"
                                >
                                  <template slot-scope="scope">
                                    <el-tooltip
                                      placement="right"
                                      effect="dark"
                                      :content="
                                        `Limit: ${scope.row
                                          .currentPlanFeatureLimit -
                                          scope.row
                                            .currentPlanDvaBurstFeatureLimit}  | Burst Limit: ${
                                          scope.row
                                            .currentPlanDvaBurstFeatureLimit
                                        }`
                                      "
                                      :disabled="
                                        effective_date_type ||
                                          scope.row.available !== 'Available' ||
                                          !contentForm.allow_bursting
                                      "
                                    >
                                      ><span>
                                        {{
                                          scope.row.available === "Available"
                                            ? scope.row.currentPlanFeatureLimit
                                            : "-"
                                        }}
                                        <i
                                          v-if="
                                            !(
                                              effective_date_type ||
                                              scope.row.available !==
                                                'Available' ||
                                              !contentForm.allow_bursting
                                            )
                                          "
                                          style="padding-left: 5px;"
                                          class="el-icon-info"
                                        ></i>
                                      </span>
                                    </el-tooltip>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="currentPlanFeatureLimit"
                                  :label="__('Plan Limit')"
                                  v-if="contentForm.plan_type !== 'DVA'"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="available"
                                  :label="__('Availability')"
                                >
                                </el-table-column>
                              </el-table>
                            </div>
                          </el-scrollbar>
                        </el-col>
                      </el-row>
                    </div>
                    <!-- EDIT CURRENT MONTH -->
                    <div
                      v-if="
                        id !== -1 &&
                          !this.next_month_usage &&
                          (contentForm.plan_type === 'IVA' ||
                            contentForm.plan_type === 'Biometrics' ||
                            (contentForm.plan_type === 'DVA' &&
                              !this.effective_date_type))
                      "
                      v-loading="isLoading || isReceiving"
                    >
                      <el-row type="flex">
                        <el-col :span="22">
                          <el-scrollbar :native="false">
                            <div class="contentListContainer">
                              <el-table
                                :data="this.acFeatureLimits"
                                style="width: 100%; line-height: 14px;"
                                :cell-style="{
                                  padding: '3px',
                                  height: '18px !important'
                                }"
                              >
                                <el-table-column type="expand">
                                  <template slot-scope="scope">
                                    <div style="margin-left: 60px">
                                      <p
                                        v-if="
                                          scope.row.available === 'Available'
                                        "
                                      >
                                        {{ scope.row.featureName }} has been
                                        used
                                        <b>{{ scope.row.currentUsage }}</b>
                                        time(s).
                                      </p>
                                      <p
                                        v-if="
                                          scope.row.available ===
                                            'Not Available'
                                        "
                                      >
                                        {{ scope.row.featureName }} Feature is
                                        not available for this plan.
                                      </p>
                                    </div>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="featureName"
                                  :label="__('Feature')"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="upper_limit"
                                  :label="__('Usages/Unit')"
                                >
                                </el-table-column>

                                <el-table-column
                                  prop="currentPlanFeatureLimit"
                                  :label="__('Plan Limit')"
                                  v-if="contentForm.plan_type === 'DVA'"
                                >
                                  <template slot-scope="scope">
                                    <el-tooltip
                                      placement="right"
                                      effect="dark"
                                      :content="
                                        `Limit: ${scope.row
                                          .currentPlanFeatureLimit -
                                          scope.row
                                            .currentPlanDvaBurstFeatureLimit}  | Burst Limit: ${
                                          scope.row
                                            .currentPlanDvaBurstFeatureLimit
                                        }`
                                      "
                                      :disabled="
                                        scope.row.available !== 'Available' ||
                                          !contentForm.allow_bursting
                                      "
                                    >
                                      ><span>
                                        {{
                                          scope.row.available === "Available"
                                            ? scope.row.currentPlanFeatureLimit
                                            : "-"
                                        }}
                                        <i
                                          v-if="
                                            scope.row.available ===
                                              'Available' &&
                                              contentForm.allow_bursting
                                          "
                                          style="padding-left: 5px;"
                                          class="el-icon-info"
                                        ></i>
                                      </span>
                                    </el-tooltip>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="currentPlanFeatureLimit"
                                  :label="__('Plan Limit')"
                                  v-if="contentForm.plan_type !== 'DVA'"
                                >
                                </el-table-column>
                                <el-table-column
                                  prop="accountFeatureLimit"
                                  :label="__('Account Limit')"
                                >
                                  <template slot-scope="scope">
                                    <el-tooltip
                                      placement="right"
                                      effect="dark"
                                      :content="
                                        'Includes contributions from other plans'
                                      "
                                      :disabled="
                                        scope.row.accountFeatureLimit ===
                                          scope.row.currentPlanFeatureLimit ||
                                          scope.row.accountFeatureLimit === '-'
                                      "
                                    >
                                      ><span>
                                        {{ scope.row.accountFeatureLimit }}
                                        <i
                                          v-if="
                                            !(
                                              scope.row.accountFeatureLimit ===
                                                scope.row
                                                  .currentPlanFeatureLimit ||
                                              scope.row.accountFeatureLimit ===
                                                '-'
                                            )
                                          "
                                          style="padding-left: 5px;"
                                          class="el-icon-info"
                                        ></i>
                                      </span>
                                    </el-tooltip>
                                  </template>
                                </el-table-column>
                                <el-table-column
                                  prop="usagesLeft"
                                  :label="__('Usages Left')"
                                >
                                </el-table-column>
                              </el-table>
                            </div>
                          </el-scrollbar>
                        </el-col>
                      </el-row>
                    </div>
                  </div>
                </el-col>
                <!-- IVA PLAN HIERARCHY -->
                <el-col :span="8" :offset="1">
                  <div style="padding-top: calc(100vh - 81vh)">
                    <plan-hierarchy
                      :series-data="contentForm.series_data"
                      v-if="contentForm.plan_type === 'IVA'"
                    >
                    </plan-hierarchy>
                  </div>
                </el-col>
              </el-row>
            </el-form>
            <el-row type="flex">
              <el-col :span="22" :offset="6">
                <save-button
                  type="primary"
                  @click="validatePlanUpdate"
                  class="submitBtn"
                  v-loading="isSubmitting"
                  :disabled="!canWrite() || contentForm.ac_id === ''"
                  :primaryKey="id"
                  variant="AssignUpdate"
                />

                <el-button @click="handleCancelBtn" class="cancelBtn"
                  >{{ __("Cancel") }}
                </el-button>
              </el-col>
            </el-row>
          </div>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import PageHeader from "@/components/PageHeader";
import SaveButton from "@/components/SaveButton";
import { mapActions, mapGetters, mapState } from "vuex";
import { EventBus } from "@/EventBus";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import PlanHierarchy from "@/views/billing/business-plans/pages/PlanHierarchy";
import moment from "moment-timezone";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    SaveButton,
    PlanHierarchy
  },
  data() {
    const checkUnitsGraterThanZero = (rule, value, callback) => {
      if (this.contentForm.allocated_unit < 1) {
        callback(__("Units must be grater than zero"));
      } else {
        callback();
      }
    };

    const validateEffectiveFrom = (rule, value, callback) => {
      if (this.contentForm.current_effective_from === "") {
        callback(__("Date required"));
      } else if (
        this.formAction === "add" &&
        moment(this.contentForm.current_effective_from) <
          moment().startOf("month")
      ) {
        callback(__("Date invalid"));
      } else {
        callback();
      }
    };

    const validateEffectiveTo = (rule, value, callback) => {
      if (
        this.contentForm.current_effective_to === "" &&
        !this.contentForm.current_is_perpetual
      ) {
        callback(__("Date required"));
      }
      if (
        this.contentForm.current_effective_to !== "" &&
        !this.contentForm.current_is_perpetual
      ) {
        if (
          moment(this.contentForm.current_effective_to).startOf("month") <
          moment(this.contentForm.current_effective_from).startOf("month")
        ) {
          callback(__("Date invalid"));
        }
      }
      callback();
    };

    const validateNextEffectiveFrom = (rule, value, callback) => {
      if (
        this.contentForm.next_effective_from === "" &&
        this.effective_date_type
      ) {
        callback(__("Date required"));
      } else if (
        moment(this.contentForm.next_effective_from) <
          moment().startOf("month") &&
        this.effective_date_type
      ) {
        callback(__("Date invalid"));
      } else {
        callback();
      }
    };

    const validateNextEffectiveTo = (rule, value, callback) => {
      if (
        this.contentForm.next_effective_to === "" &&
        !this.contentForm.next_is_perpetual &&
        this.effective_date_type
      ) {
        callback(__("Date required"));
      }
      if (
        this.contentForm.next_effective_to !== "" &&
        !this.contentForm.next_is_perpetual &&
        this.effective_date_type
      ) {
        if (
          moment(this.contentForm.next_effective_to).startOf("month") <
          moment(this.contentForm.next_effective_from).startOf("month")
        ) {
          callback(__("Date invalid"));
        }
      }
      callback();
    };

    const validateNextAllocatedUnits = (rule, value, callback) => {
      this.handleDateChange();
      if (
        this.contentForm.next_effective_from !== "" &&
        this.contentForm.next_allocated_unit < 1
      ) {
        callback(__("Units must be grater than zero"));
      }
      callback();
    };

    return {
      sp_allocated_unit: 1,
      sp_burst_unit: 1,
      acPlan: {},
      isSubmitting: false,
      acPlanFormKey: 0,
      isReceiving: false,
      selectedServiceProvider: [],
      selectedAccounts: [],
      selectedAvailablePlans: [],
      unit: [],
      burst_unit: [],
      max_unit: 0,
      max_burst_unit: 0,
      tooltip_message: 0,
      burst_tooltip_message: 0,
      no_account_data_text: __("Please select a Service Provider."),
      no_plan_data_text: __("Please select an Account"),
      featureLimits: [],
      acFeatureLimits: [],
      next_month_usage: false,
      current_effective_from: "",
      current_effective_to: "",
      current_is_perpetual: true,
      next_effective_from: "",
      next_effective_to: "",
      next_is_perpetual: true,
      effective_date_type: false,
      allocated_unit: 0,
      next_from: "",
      next_to: "",
      next_perpetual: true,
      next_allocated_unit: 0,
      rules: {
        ac_id: [
          {
            required: true,
            trigger: "blur"
          }
        ],
        sp_plan_id: [
          {
            required: true,
            trigger: "blur"
          }
        ],
        allocated_unit: [
          {
            required: true,
            trigger: "blur",
            validator: checkUnitsGraterThanZero
          }
        ],
        current_effective_from: [
          {
            required: true,
            trigger: "blur",
            validator: validateEffectiveFrom
          }
        ],
        current_effective_to: [
          {
            trigger: "blur",
            validator: validateEffectiveTo
          }
        ],
        next_effective_from: [
          {
            trigger: "blur",
            validator: validateNextEffectiveFrom
          }
        ],
        next_effective_to: [
          {
            trigger: "blur",
            validator: validateNextEffectiveTo
          }
        ],
        next_allocated_unit: [
          {
            trigger: "blur",
            validator: validateNextAllocatedUnits
          }
        ]
      }
    };
  },
  computed: {
    ...mapState("acplans", {
      isLoading: state => state.isLoading,
      availablePlans: state => state.availablePlans,
      accounts: state => state.accounts
    }),

    ...mapState("businessplans", {
      businessPlans: state => state.businessPlans
    }),

    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders
    }),
    ...mapState("spplans", {
      availableFeatures: state => state.availableFeatures
    }),
    ...mapState("appfeatures", {
      appFeatures: state => state.appFeatures,
      acAppFeatures: state => state.acAppFeatures
    }),
    ...mapGetters("app", {
      displayDateFormat: "displayDateFormat"
    }),

    setPageHeader: function() {
      return this.id === -1
        ? __("Assign :plan_type Plan", {
            plan_type: this.contentForm.plan_type
          })
        : __("Edit :plan_type Plan", {
            plan_type: this.contentForm.plan_type
          });
    },

    disableNextMonthUsage() {
      if (this.contentForm.plan_type === "DVA") {
        if (
          moment(this.contentForm.current_effective_to).month() ===
          moment().month()
        ) {
          return true;
        }
      }
      return false;
    }
  },

  beforeRouteLeave(to, from, next) {
    this.unit = 0;
    this.no_account_data_text = __("Please select a Service Provider.");
    this.no_plan_data_text = __("Please select an Account");
    this.selectedAccounts = "";
    this.selectedAvailablePlans = "";
    this.tooltip_message = 0;
    this.burst_tooltip_message = 0;
    this.acPlan = {};
    this.acFeatureLimits = [];
    this.effective_date_type = false;
    next();
  },

  methods: {
    ...mapActions("acplans", {
      createAcPlan: "createAcPlan",
      updateAcPlan: "updateAcPlan",
      getAcPlansForSelectedAccount: "getAcPlansForSelectedAccount",
      getAccountsForServiceProvider: "getAccountsForServiceProvider",
      getAllocatedPlanAndUpdatedUnits: "getAllocatedPlanAndUpdatedUnits",
      fetchSelectedAccount: "fetchSelectedAccount",
      deleteContentMethod: "deleteAcPlan",
      undoDeleteContent: "undoDeleteAcPlan",
      getAccountFeatureLimits: "getAccountFeatureLimits"
    }),
    ...mapActions("appfeatures", {
      getAcAppFeatures: "getAcAppFeatures",
      getAppFeatures: "getAppFeatures"
    }),
    ...mapActions("businessplans", {
      getBusinessPlans: "getBusinessPlans"
    }),
    ...mapActions("serviceproviders", {
      getServiceProviders: "getServiceProviders"
    }),
    ...mapActions("spplans", {
      getAvailableFeaturesForSpPlan: "getAvailableFeaturesForSpPlan"
    }),

    async showPlans(ac_id) {
      this.isReceiving = true;
      await this.getAcPlansForSelectedAccount({
        ac_id: ac_id,
        plan_type: this.contentForm.plan_type
      });
      this.isReceiving = false;
    },

    async getFeatureLimits(sp_plan_id) {
      this.isReceiving = true;
      await this.getAppFeatures({
        plan_type: this.contentForm.plan_type
      });
      this.getAvailableFeaturesForSpPlan({
        sp_plan_id: sp_plan_id
      }).then(() => {
        let available_features = _.map(this.availableFeatures, "feature_id");
        let app_features = this.appFeatures.filter(
          feature => feature.upper_limit > 0
        );

        // ACFEATURELIMITS - USED FOR EXISTING PLANS
        this.acFeatureLimits = this.acAppFeatures.map(feature => ({
          featureName: _.map(
            feature.feature_name.toLowerCase().split(" "),
            string => string.charAt(0).toUpperCase() + string.slice(1)
          ).join(" "),
          currentPlanFeatureLimit:
            this.contentForm.allocated_unit * feature.upper_limit +
            this.getCurrentPlanDvaBurstFeatureLimit(feature.upper_limit),
          otherAcPlanFeatureLimit: feature.otherAcPlanFeatureLimit,
          accountFeatureLimit:
            feature.otherAcPlanFeatureLimit +
            this.contentForm.allocated_unit * feature.upper_limit +
            this.getCurrentPlanDvaBurstFeatureLimit(feature.upper_limit),
          currentUsage: feature.currentUsage,
          usagesLeft:
            feature.otherAcPlanFeatureLimit +
            this.contentForm.allocated_unit * feature.upper_limit +
            this.getCurrentPlanDvaBurstFeatureLimit(feature.upper_limit) -
            feature.currentUsage,
          currentPlanDvaBurstFeatureLimit: this.getCurrentPlanDvaBurstFeatureLimit(
            feature.upper_limit
          ),
          upper_limit: feature.upper_limit,
          available: _.includes(available_features, feature.feature_id)
            ? "Available"
            : "Not Available"
        }));

        // FEATURELIMITS - USED FOR NEW/UPCOMING PLANS
        this.featureLimits = app_features.map(feature => ({
          featureName: _.map(
            feature.feature_name.toLowerCase().split(" "),
            string => string.charAt(0).toUpperCase() + string.slice(1)
          ).join(" "),
          currentPlanFeatureLimit:
            this.contentForm.allocated_unit * feature.upper_limit +
            this.getCurrentPlanDvaBurstFeatureLimit(feature.upper_limit),

          currentPlanDvaBurstFeatureLimit: this.getCurrentPlanDvaBurstFeatureLimit(
            feature.upper_limit
          ),
          upper_limit: feature.upper_limit,
          available: _.includes(available_features, feature.feature_id)
            ? __("Available")
            : __("Not Available")
        }));

        // Filtering Feature Availability
        this.acFeatureLimits.forEach(feature => {
          if (feature.available === "Not Available") {
            feature.currentPlanFeatureLimit = "-";
            feature.accountFeatureLimit = "-";
            feature.currentUsage = "-";
            feature.usagesLeft = "-";
            feature.currentPlanDvaBurstFeatureLimit = "-";
            feature.upper_limit = "-";
          }
        });
        this.featureLimits.forEach(feature => {
          if (feature.available === "Not Available") {
            feature.currentPlanFeatureLimit = "-";
            feature.currentPlanDvaBurstFeatureLimit = "-";
            feature.upper_limit = "-";
          }
        });
        // FILTERING BIOMETRICS FEATURE
        if (this.contentForm.plan_type === "Biometrics") {
          const condition = element => element.available === "Not Available";
          this.featureLimits.splice(
            0,
            this.featureLimits.length,
            ...this.featureLimits.filter(element => !condition(element))
          );

          this.featureLimits = this.featureLimits.map(feature => {
            if (feature.featureName === "Biometrics Verify") {
              feature.featureName = "Biometrics Transaction";
            }
            return feature;
          });

          this.acFeatureLimits.splice(
            0,
            this.acFeatureLimits.length,
            ...this.acFeatureLimits.filter(element => !condition(element))
          );

          this.acFeatureLimits = this.acFeatureLimits.map(feature => {
            if (feature.featureName === "Biometrics Verify") {
              feature.featureName = "Biometrics Transaction";
            }
            return feature;
          });
        }
      });
      if (this.contentForm.ac_id) {
        await this.getAccountFeatureLimits({
          ac_id: this.contentForm.ac_id
        });
      }
      this.isReceiving = false;
    },

    updateEffectiveTo() {
      this.contentForm.current_effective_to = this.current_is_perpetual
        ? ""
        : this.contentForm.current_effective_to;
    },

    updateNextEffectiveTo() {
      this.contentForm.next_effective_to = this.next_is_perpetual
        ? ""
        : this.contentForm.next_effective_to;
    },

    getCurrentPlanDvaBurstFeatureLimit(featureUpperLimit) {
      if (
        this.contentForm.plan_type === "DVA" &&
        this.contentForm.allow_bursting &&
        !this.effective_date_type
      ) {
        let currentPlanLimit =
          this.contentForm.allocated_unit * featureUpperLimit;
        let currentPlanDvaBurstFeatureLimit = Math.ceil(
          (currentPlanLimit * this.contentForm.burst_unit) / 100
        );
        return currentPlanDvaBurstFeatureLimit;
      }
      return 0;
    },

    //Changes when bp changes
    async changeUnitAndPrice(sp_plan_id) {
      if (this.formAction === "edit") {
        if (this.contentForm.sp_plan_id !== this.contentFormInfo.sp_plan_id) {
          let plan_item = _.find(this.availablePlans, {
            sp_plan_id: sp_plan_id
          });
          this.contentForm.pooling_method = plan_item.sp_pooling_method;
          this.sp_allocated_unit = plan_item.sp_allocated_unit;
          this.sp_burst_unit = plan_item.sp_burst_unit;
          this.contentForm.allocated_unit = 0;
          this.contentForm.burst_unit = 0;
          this.contentForm.allow_bursting = plan_item.sp_allow_bursting;
          this.contentForm.series_data = plan_item.series_data;
          this.contentForm.sp_plan_list_price = plan_item.sp_plan_list_price;
        } else {
          let plan_item = _.find(this.availablePlans, {
            sp_plan_id: sp_plan_id
          });
          this.sp_allocated_unit = plan_item.sp_allocated_unit;
          this.sp_burst_unit = plan_item.sp_burst_unit;
          this.contentForm.allocated_unit = this.contentFormInfo.allocated_unit;
          this.contentForm.pooling_method = this.contentFormInfo.pooling_method;
          this.contentForm.burst_unit = this.contentFormInfo.burst_unit;
          this.contentForm.allow_bursting = plan_item.sp_allow_bursting;
          this.contentForm.series_data = plan_item.series_data;
          this.contentForm.sp_plan_list_price = plan_item.sp_plan_list_price;

          this.next_from = this.content.next_effective_from;
          this.next_to = this.content.next_effective_to;
          this.next_perpetual = this.content.current_is_perpetual;
          this.allocated_unit = this.content.allocated_unit;
          this.next_allocated_unit = this.content.next_allocated_unit;
        }
      } else {
        this.effective_date_type = false;
        let plan_item = _.find(this.availablePlans, {
          sp_plan_id: sp_plan_id
        });
        this.contentForm.pooling_method = plan_item.sp_pooling_method;
        this.sp_allocated_unit = plan_item.sp_allocated_unit;
        this.sp_burst_unit = plan_item.sp_burst_unit;
        this.contentForm.allocated_unit = 0;
        this.contentForm.burst_unit = 0;
        this.contentForm.allow_bursting = plan_item.sp_allow_bursting;
        this.contentForm.series_data = plan_item.series_data;
        this.contentForm.sp_plan_list_price = plan_item.sp_plan_list_price;
        this.contentForm.unit_price = plan_item.sp_plan_list_price;
      }
      await this.getFeatureLimits(sp_plan_id);
    },

    handleCancelBtn() {
      this.resetForm("contentForm");
      this.featureLimits = [];
      this.next_month_usage = false;
      this.effective_date_type = false;
      this.handleCancel();
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },

    validatePlanUpdate() {
      if (this.contentForm.plan_type === "DVA" && this.id !== -1) {
        if (
          this.contentForm.allocated_unit !== this.allocated_unit ||
          this.contentForm.next_allocated_unit !== this.next_allocated_unit
        ) {
          let message = "";
          if (
            this.contentForm.allocated_unit !== this.allocated_unit &&
            this.contentForm.next_allocated_unit !== this.next_allocated_unit
          ) {
            message = __(
              "Update units for current and upcoming effective dates?"
            );
          } else if (this.contentForm.allocated_unit !== this.allocated_unit) {
            message = __("Update units for current effective dates?");
          } else {
            message = __("Update units for upcoming effective dates?");
          }

          this.$confirm(message, __("Warning"), {
            confirmButtonText: __("Confirm"),
            cancelButtonText: __("Cancel"),
            type: "warning",
            showClose: false
          })
            .then(() => {
              this.createOrEditAcPlan();
            })
            .catch(() => {});
        } else {
          this.createOrEditAcPlan();
        }
      } else {
        this.createOrEditAcPlan();
      }
    },

    handleDateChange() {
      this.contentForm.next_effective_from =
        this.contentForm.next_effective_from !== null
          ? this.contentForm.next_effective_from
          : "";
    },

    recalculateUsage() {
      if (this.contentForm.plan_type === "DVA" && !this.effective_date_type) {
        if (
          moment(this.contentForm.next_effective_to).startOf("month") <
            moment(this.contentForm.next_effective_from).startOf("month") ||
          moment(this.contentForm.next_effective_from) <
            moment().startOf("month")
        ) {
          this.contentForm.next_effective_from = this.next_from;
          this.contentForm.next_effective_to = this.next_to;
          this.contentForm.next_allocated_unit = this.next_allocated_unit;
        }
        this.$refs.contentForm.validate();
        this.handleUnitsChange(false);
      } else if (
        this.contentForm.plan_type === "DVA" &&
        this.effective_date_type
      ) {
        this.handleUnitsChange(true);
      }
    },

    createOrEditAcPlan() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createAcPlan : this.updateAcPlan;

          process(this.contentForm)
            .then(data => {
              this.id === -1
                ? this.$message({
                    name: "success",
                    message: __("Business Plan assigned successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("Assigned Plan updated successfully")
                  });
              this.isSubmitting = false;
              EventBus.$emit("list-changed", data.data);
              this.handleCancelBtn();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    updateUnitPrice() {
      this.contentForm.unit_price = this.contentForm.override_price
        ? this.contentForm.unit_price
        : this.contentForm.sp_plan_list_price;
    },

    handleUnitsChange(isNext = false) {
      let allocatedUnit = this.contentForm.allocated_unit;
      if (isNext) {
        allocatedUnit = this.contentForm.next_allocated_unit;
      }
      _.map(this.acFeatureLimits, acFeatureLimit => {
        if (allocatedUnit >= 0) {
          if (acFeatureLimit.available === "Available") {
            let currentPlanDvaBurstFeatureLimit = this.getCurrentPlanDvaBurstFeatureLimit(
              acFeatureLimit.upper_limit
            );
            acFeatureLimit.currentPlanDvaBurstFeatureLimit = currentPlanDvaBurstFeatureLimit;

            let currentPlanFeatureLimit =
              allocatedUnit * acFeatureLimit.upper_limit +
              +currentPlanDvaBurstFeatureLimit;
            acFeatureLimit.currentPlanFeatureLimit = currentPlanFeatureLimit;

            let accountFeatureLimit =
              acFeatureLimit.otherAcPlanFeatureLimit + currentPlanFeatureLimit;
            acFeatureLimit.accountFeatureLimit = accountFeatureLimit;

            let usagesLeft = accountFeatureLimit - acFeatureLimit.currentUsage;
            acFeatureLimit.usagesLeft = usagesLeft;
          }
        }
      });

      _.map(this.featureLimits, featureLimit => {
        if (allocatedUnit >= 0) {
          if (featureLimit.available === "Available") {
            let currentPlanDvaBurstFeatureLimit = this.getCurrentPlanDvaBurstFeatureLimit(
              featureLimit.upper_limit
            );
            featureLimit.currentPlanDvaBurstFeatureLimit = currentPlanDvaBurstFeatureLimit;

            let currentPlanFeatureLimit =
              allocatedUnit * featureLimit.upper_limit +
              +this.getCurrentPlanDvaBurstFeatureLimit(
                featureLimit.upper_limit
              );
            featureLimit.currentPlanFeatureLimit = currentPlanFeatureLimit;
          }
        }
      });
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "edit") {
          this.isReceiving = true;
          await this.fetchSelectedAccount(this.contentForm.ac_id);
          await this.getAllocatedPlanAndUpdatedUnits(
            this.contentForm.ac_plan_id
          );
          await this.getAcAppFeatures({
            ac_id: this.contentForm.ac_id,
            plan_type: this.contentForm.plan_type,
            ac_plan_id: this.contentForm.ac_plan_id
          });
          await this.changeUnitAndPrice(this.contentForm.sp_plan_id);
          this.isReceiving = false;
        }
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/node_common.scss";

::v-deep .plan-form-dialog {
  max-height: 90vh !important;
}

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-select-dropdown__item.selected {
    color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }

  .el-select__tags {
    ::v-deep .el-tag {
      background-color: $content-theme-color !important;
      border-color: $content-theme-color !important;

      .el-tag__close {
        background-color: $content-theme-color !important;
      }
    }
  }
}

::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}

::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}

::v-deep .el-slider {
  border-color: $content-theme-color;
  :hover {
    border-color: $content-theme-color;
  }

  .el-slider__bar {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }

  .el-slider__button {
    border-color: $content-theme-color;
    color: $content-theme-color;
  }

  ::v-deep .el-input-number--small .el-input-number__increase {
    :hover {
      color: $content-theme-color;
    }
  }
  ::v-deep .el-input-number--small .el-input-number__decrease {
    :hover {
      color: $content-theme-color;
    }
  }
}
.cancelBtn {
  border: none;
  height: 44px;
  &:hover {
    background: lightgrey;
    color: white;
  }
}
.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}
</style>
