<script>
import { mapActions, mapState } from "vuex";
import List from "./pages/listAcPlan";
import CreateOrEdit from "./pages/createOrEditAcPlan";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "AcPlansIndex",
  mixins: [BaseIndex],
  components: {
    List,
    CreateOrEdit
  },

  data() {
    return {
      selectedContent: this.initContent()
    };
  },

  computed: {
    ...mapState("acplans", {
      contents: state => state.acPlans
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    })
  },

  methods: {
    ...mapActions("acplans", {
      getAccountsForSP: "getAccountsForSP",
      setBusinessPlanFilter: "setBusinessPlanFilter"
    }),
    ...mapActions("appfeatures", {
      getAppFeatures: "getAppFeatures"
    }),

    initContent(planType = "IVA") {
      return {
        ac_id: "",
        sp_plan_id: "",
        unit_name: "",
        plan_type: planType,
        allocated_unit: 0,
        burst_unit: 0,
        allow_bursting: false,
        qfm_pooling_method: "Strict",
        sp_plan_list_price: 0,
        unit_price: 0,
        current_effective_from: "",
        current_effective_to: "",
        current_is_perpetual: true,
        next_effective_from: "",
        next_effective_to: "",
        next_is_perpetual: true,
        next_allocated_unit: 0
      };
    },
    handleAdd(planType) {
      this.formAction = "add";
      this.setSelectedObject(-1, this.initContent(planType));
      this.getAccountsForSP({
        sp_id: this.selectedServiceProviderId,
        plan_type: planType
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    this.setBusinessPlanFilter("IVA");
    this.initContent();
    next();
  },
  mounted() {
    this.contentType = "Account Plan";
    this.primaryKey = "ac_plan_id";
    this.scope = "sp";
    this.getAppFeatures({
      plan_type: "IVA"
    });
    this.effective_date_type = false;
  }
};
</script>

<style lang="scss" scoped></style>
